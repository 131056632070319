/* header */
header {
  position: sticky;
  top: 24px;
  max-width: 1400px;
  height: 72px;
  margin: auto;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(85px);
  border-radius: 20px;
  padding: 0px 40px;
}
.Navlinks {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navlinks .navlinks {
  margin-left: 40px;
}
.logo {
  cursor: pointer;
  width: 220px;
  height: auto;
  margin-left: 20px;
}
.Navlinks > .navlinks > ul {
  display: inline-flex;
  align-items: center;
  justify-items: center;
}
.Navlinks > .navlinks > ul > li {
  list-style-type: none;
  margin-right: 30px;
}
.navbutton {
  cursor: pointer;
  color: #31f080 !important;
  border: 2px solid #31f080;
  opacity: 0.8 !important;
  padding: 2px 10px;
  border-radius: 8px;
}
.navbutton:hover {
  opacity: 1 !important;
}
.navlinks_right {
  display: inline-flex;
  align-items: center;
  justify-items: center;
}
.navlinks_right > a {
  color: #ffffff;
  opacity: 0.6;
  margin-left: 30px;
  font-size: 16px;
  line-height: 27px;
}
.navbutton .navbtn_link {
  color: #31f080 !important;
  text-align: center;
  margin-left: 30px;
  opacity: 0.2 !important;
}
.Navlinks > .navlinks > ul > li > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  opacity: 0.6;
  text-decoration: none;
}
.active {
  color: #ffffff !important;
  opacity: 1 !important;
}
.navlinks > ul > li > a:hover {
  color: #ffffff;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.navlinks_right > a:hover {
  color: #ffffff;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}
.toggle {
  display: none;
  width: 45px;
  height: 45px;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
  border-radius: 90%;
  float: right;
}
.toggle1 {
  position: absolute;
  top: 30px;
  right: 70px;
  display: none;
  width: 45px;
  height: 45px;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
  float: right;
}
/* sidebar */
.menu {
  position: fixed;
  top: 0;
  background: #000000d9;
  z-index: 100;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  backdrop-filter: blur(3px);
}
.menu ul {
  position: relative;
  left: -70px;
  top: 60px;
  list-style: none;
  margin-bottom: 200px;
}
.menu ul li {
  margin-top: 40px;
  transform: translateY(-50%);
}
.menu ul li .links {
  text-decoration: none;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 51px;
}
.menu ul li .links:hover {
  color: #6dd400;
  transition: 0.7s all ease-in-out;
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* media queries */
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
  header {
    padding: 10px 20px !important;
    margin: 0px 10px;
  }
  .menu {
    width: 100%;
    display: flex;
  }
  .logo {
    max-width: 220px;
    width: 100%;
    margin-left: 0px;
  }
  .navlinks {
    display: none;
  }
  .navlinks_right {
    display: none;
  }
  .toggle {
    display: flex;
    width: 45px;
    height: 45px;
    margin-left: auto;
  }
  .toggle1 {
    display: flex;
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  header {
    padding: 0px 40px !important;
    margin: 0px 10px;
  }
  .menu {
    width: 100%;
    display: flex;
  }
  .toggle {
    display: flex;
    width: 45px;
    height: 45px;
  }
  .toggle1 {
    display: flex;
    width: 30px;
    height: 30px;
  }
  .navlinks {
    display: none;
  }
  .navlinks_right {
    display: none;
  }
  .Navlinks > .navlinks > ul > li {
    margin-right: 10px;
  }
}
@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  header {
    margin: 0px 10px;
  }
  .Navlinks > .navlinks > ul > li {
    margin-right: 20px;
  }
}
